import { Component, OnInit, NgZone, ViewChild, ElementRef, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import * as Highcharts from 'highcharts';
import * as Highstock from "highcharts/highstock";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
import { HttpClient } from '@angular/common/http';
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { map } from 'rxjs/operators';
import am4themes_dark from "@amcharts/amcharts4/themes/dark"
import * as xlsx from 'xlsx';
import config from "../../../../configuration.json"
import * as $ from 'jquery'
import {TableserviceService} from '../../services/tableservice.service';
import { ExportToCsv } from 'export-to-csv';

var monthlyFlightCycles = [];
var monthlyFlightHours = [];
am4core.useTheme(am4themes_animated);
am4core.useTheme(am4themes_dark);


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  encapsulation: ViewEncapsulation.Emulated
})
export class DashboardComponent implements OnInit {
  @ViewChild('table2') table2: ElementRef;

  selectedRowIds: any = [];
  mapChart: any;
  placeSeries: any;
  place: any;
  dailytotals: any = [];
  timeDiff: any = [];
  dailytotalsAOG: any = [];
  alrTableData: any = [];
  allOverlayData:any = [];
  onGroundArrayFinal:any = {};
  allAssets: any;
  all: any = [];
  hex: any = [];
  serialNumber: any = [];
  monthEnd: any;
  month: any;
  day: any;
  monthYear: string; // Variables for daily activity cards
  fleetActivityDayAircraft: number = 0; // Variables for daily activity cards
  fleetActivityDayEngine: number = 0; // Variables for daily activity cards
  fleetActivityMonthAircraft: number = 0; // Variables for daily activity cards
  fleetActivityMonthEngine: number = 0; // Variables for daily activity cards
  filter;
  term1;
  container: any;
  fleetActivityDailyLoaded: boolean = false; // Pie chart loader
  fleetActivityMonthlyLoaded: boolean = false; // Pie chart loade
  p;
  p1;
  locationArr = [];
  inAirLocationArr = [];
  gbteLocationArr = [];
  startDateTime = [];
  allArr = [];
  captureChangeAllArr = [];
  aircraftInAirLocationArr = [];
  engineInAirLocationArr = [];
  aircraftGbteLocationArr = [];
  engineGbteLocationArr = [];
  alrTableDataTwo = [];
  alrTableLoader: boolean = true;

  constructor(
    public tableService :TableserviceService,
    public httpClient: HttpClient
  ) { }

  captureChange(e: any): void {
    this.alrTableDataTwo = this.alrTableData;
    this.captureChangeAllArr = this.allArr;
    this.alrTableLoader = true; // Show loader whilst typing

    /* console.log(this.term1);
    console.log(this.filter);
    console.log(this.alrTableData);
    console.log(this.dailytotals);
    console.log(this.allOverlayData);
    console.log(this.allArr); */
    // Trial of changing engine to Engine
    // console.log(this.table2);
    var sendThisDataToMap: any[] = [];
    var overlayDataArr = [];
    sendThisDataToMap = this.alrTableDataTwo.filter((row: any) => {
      var exist = false;
      Object.keys(row).forEach((key: any) => {
        let str = JSON.stringify(row[key]);
        if (str.includes(this.term1))
          exist = true;
      });
      if (exist) {
        this.alrTableDataTwo = [];
        //console.log('Empty');
        //console.log(this.alrTableDataTwo);
        //console.log('alloverlaydata.length');
        //console.log(this.allOverlayData.length);
        //console.log('search row');
        //console.log(row);
        this.alrTableDataTwo.push(row);
        //console.log('After pushing in AlrTable TWO 113');
        //console.log(this.alrTableDataTwo);

        for (var c = 0; c < this.allOverlayData.length; c++) {
          if (row.serialNumber == this.allOverlayData[c].serialNumber) {
            // console.log('match into if loop');
            // console.log(this.allOverlayData[c]);
            overlayDataArr.push(this.allOverlayData[c]);
          }
        }
        // console.log(overlayDataArr.length);
        // console.log(overlayDataArr);
      };
      //console.log(row.length);
      //console.log(row)
    })
    sendThisDataToMap = overlayDataArr;
    // console.log("sendThisDataToMap");
    // console.log(sendThisDataToMap);
    this.processingMapOverlay(sendThisDataToMap);
  }

  processingMapOverlay(overlayData){
    //console.log('processingMapOverlay');
    var countAircraftAssets = 0;
    var countEngineAssets = 0;
    var aircraftLocationData = overlayData;
    //console.log(aircraftLocationData);
    // Count of Engine Assets and Aircraft Assets
    //console.log(aircraftLocationData.length);
    for (var a = 0; a < aircraftLocationData.length; a++) {
      //console.log(a);
      //console.log(aircraftLocationData[a].assetType);
      if (aircraftLocationData[a].assetType == 'Aircraft') {
        countAircraftAssets++;
      } else {
        countEngineAssets++;
      }
    }
    // console.log(this.mapChart.series)
    // console.log("beforeLoop");
    var length = this.mapChart.series.length
    for (var i = 1;i <length;i++) {
      // console.log(i)
      // console.log(this.mapChart.series[1]);
      this.mapChart.series.removeIndex(1).dispose();
      // console.log(length);
    }
      // console.log(this.mapChart.series)
      this.mapChart.titles.removeIndex(0).dispose();
      // top title for Aircraft Location Report
      var title = this.mapChart.titles.create();
      title.fontSize = "1em";
      title.text = "Asset Locations: \n" + countAircraftAssets + " Aircraft \n" + countEngineAssets + " Engines ";
      title.align = "left";
      title.horizontalCenter = "left";
      title.marginLeft = 20;
      title.paddingBottom = 10;
      title.fill = am4core.color("#ffffff");
      title.y = 20;
      // console.log(this.mapChart.titles);
      // console.log(aircraftLocationData);
      // console.log(__this.dailytotals.length);
      // console.log(aircraftLocationData.length);
      this.engineGbteLocationArr = [];
      this.aircraftInAirLocationArr = [];
      this.engineInAirLocationArr = [];
      this.aircraftGbteLocationArr = [];
      this.captureChangeAllArr = [];

      for (var b = 0; b < aircraftLocationData.length; b++) {
        // console.log(aircraftLocationData[b]);
        var aircraftInAirLocation: any = {};
        var engineInAirLocation: any = {};
        var aircraftGbteLocation: any = {};
        var engineGbteLocation: any = {};

        // AirCraft Asset & inAirFlag = 1
        if (aircraftLocationData[b].assetType == 'Aircraft' && aircraftLocationData[b].inAirFlag == '1') {

          var latNum: number = +aircraftLocationData[b].lastLat;
          var lngNum: number = +aircraftLocationData[b].lastLng;

          aircraftInAirLocation.assetModelSeries = aircraftLocationData[b].assetModelSeries;
          aircraftInAirLocation.airborne = aircraftLocationData[b].airborne;
          aircraftInAirLocation.airportIncursion = aircraftLocationData[b].airportIncursion;
          aircraftInAirLocation.arrivalAirport = aircraftLocationData[b].arrivalAirport;
          aircraftInAirLocation.arrivalCity = aircraftLocationData[b].arrivalCity;
          aircraftInAirLocation.arrivalCountry = aircraftLocationData[b].arrivalCountry;
          aircraftInAirLocation.assetType = aircraftLocationData[b].assetType;
          aircraftInAirLocation.assetModelSeries = aircraftLocationData[b].assetModelSeries;
          aircraftInAirLocation.operator = aircraftLocationData[b].operator;
          aircraftInAirLocation.date = aircraftLocationData[b].date;
          aircraftInAirLocation.departureAirport = aircraftLocationData[b].departureAirport;
          aircraftInAirLocation.departureCity = aircraftLocationData[b].departureCity;
          aircraftInAirLocation.departureCountry = aircraftLocationData[b].departureCountry;
          aircraftInAirLocation.flightCycles = aircraftLocationData[b].flightCycles;
          aircraftInAirLocation.flightHours = aircraftLocationData[b].flightHours;
          aircraftInAirLocation.hex = aircraftLocationData[b].hex;
          aircraftInAirLocation.idClientInfo = aircraftLocationData[b].idClientInfo;
          aircraftInAirLocation.idDailyTotals = aircraftLocationData[b].idDailyTotals;
          aircraftInAirLocation.inAirFlag = aircraftLocationData[b].inAirFlag;
          aircraftInAirLocation.lastFlightFlag = aircraftLocationData[b].lastFlightFlag;
          aircraftInAirLocation.lastLat = latNum;
          aircraftInAirLocation.lastLng = lngNum;
          aircraftInAirLocation.lessor = aircraftLocationData[b].lessor;
          aircraftInAirLocation.registration = aircraftLocationData[b].registration;
          aircraftInAirLocation.serialNumber = aircraftLocationData[b].serialNumber;
          aircraftInAirLocation.startDateTime = aircraftLocationData[b].startDateTime;
          aircraftInAirLocation.stopDateTime = aircraftLocationData[b].stopDateTime;
          aircraftInAirLocation.time = aircraftLocationData[b].stopDateTime;
          aircraftInAirLocation.location = "IN AIR";

          aircraftInAirLocation.techRep = aircraftLocationData[b].techRep;
          aircraftInAirLocation.uniqueFlightId = aircraftLocationData[b].uniqueFlightId;

          if (aircraftInAirLocation.assetType == "Engine") {
            aircraftInAirLocation.msn = "ESN";
          } else {
            aircraftInAirLocation.msn = "MSN";
          }

          this.aircraftInAirLocationArr.push(aircraftInAirLocation);
          this.captureChangeAllArr.push(aircraftInAirLocation);
          //console.log(aircraftInAirLocationArr);

        } else if (aircraftLocationData[b].assetType == 'Engine' && aircraftLocationData[b].inAirFlag == '1') {

          var latNum: number = +aircraftLocationData[b].lastLat;
          var lngNum: number = +aircraftLocationData[b].lastLng;

          engineInAirLocation.assetModelSeries = aircraftLocationData[b].assetModelSeries;
          engineInAirLocation.airborne = aircraftLocationData[b].airborne;
          engineInAirLocation.airportIncursion = aircraftLocationData[b].airportIncursion;
          engineInAirLocation.arrivalAirport = aircraftLocationData[b].arrivalAirport;
          engineInAirLocation.arrivalCity = aircraftLocationData[b].arrivalCity;
          engineInAirLocation.arrivalCountry = aircraftLocationData[b].arrivalCountry;
          engineInAirLocation.assetType = aircraftLocationData[b].assetType;
          engineInAirLocation.assetModelSeries = aircraftLocationData[b].assetModelSeries;
          engineInAirLocation.operator = aircraftLocationData[b].operator;
          engineInAirLocation.date = aircraftLocationData[b].date;
          engineInAirLocation.departureAirport = aircraftLocationData[b].departureAirport;
          engineInAirLocation.departureCity = aircraftLocationData[b].departureCity;
          engineInAirLocation.departureCountry = aircraftLocationData[b].departureCountry;
          engineInAirLocation.flightCycles = aircraftLocationData[b].flightCycles;
          engineInAirLocation.flightHours = aircraftLocationData[b].flightHours;
          engineInAirLocation.hex = aircraftLocationData[b].hex;
          engineInAirLocation.idClientInfo = aircraftLocationData[b].idClientInfo;
          engineInAirLocation.idDailyTotals = aircraftLocationData[b].idDailyTotals;
          engineInAirLocation.inAirFlag =aircraftLocationData[b].inAirFlag;
          engineInAirLocation.lastFlightFlag = aircraftLocationData[b].lastFlightFlag;
          engineInAirLocation.lastLat = latNum;
          engineInAirLocation.lastLng = lngNum;
          engineInAirLocation.lessor = aircraftLocationData[b].lessor;
          engineInAirLocation.registration = aircraftLocationData[b].registration;
          engineInAirLocation.serialNumber = aircraftLocationData[b].serialNumber;
          engineInAirLocation.startDateTime = aircraftLocationData[b].startDateTime;
          engineInAirLocation.stopDateTime = aircraftLocationData[b].stopDateTime;
          engineInAirLocation.time = aircraftLocationData[b].stopDateTime;
          engineInAirLocation.location = "IN AIR";

          engineInAirLocation.techRep = aircraftLocationData[b].techRep;
          engineInAirLocation.uniqueFlightId = aircraftLocationData[b].uniqueFlightId;

          if (engineInAirLocation.assetType == "Engine") {
            engineInAirLocation.msn = "ESN";
          } else {
            engineInAirLocation.msn = "MSN";
          }

          this.engineInAirLocationArr.push(engineInAirLocation);
          this.captureChangeAllArr.push(engineInAirLocation);

        } else if (aircraftLocationData[b].assetType == 'Aircraft' && aircraftLocationData[b].inAirFlag == '0') {

          var latNum: number = +aircraftLocationData[b].lastLat;
          var lngNum: number = +aircraftLocationData[b].lastLng;

          aircraftGbteLocation.assetModelSeries = aircraftLocationData[b].assetModelSeries;
          aircraftGbteLocation.airborne = aircraftLocationData[b].airborne;
          aircraftGbteLocation.airportIncursion = aircraftLocationData[b].airportIncursion;
          aircraftGbteLocation.arrivalAirport = aircraftLocationData[b].arrivalAirport;
          aircraftGbteLocation.arrivalCity = aircraftLocationData[b].arrivalCity;
          aircraftGbteLocation.arrivalCountry = aircraftLocationData[b].arrivalCountry;
          aircraftGbteLocation.assetType = aircraftLocationData[b].assetType;
          aircraftGbteLocation.assetModelSeries = aircraftLocationData[b].assetModelSeries;
          aircraftGbteLocation.operator = aircraftLocationData[b].operator;
          aircraftGbteLocation.date = aircraftLocationData[b].date;
          aircraftGbteLocation.departureAirport = aircraftLocationData[b].departureAirport;
          aircraftGbteLocation.departureCity = aircraftLocationData[b].departureCity;
          aircraftGbteLocation.departureCountry = aircraftLocationData[b].departureCountry;
          aircraftGbteLocation.flightCycles = aircraftLocationData[b].flightCycles;
          aircraftGbteLocation.flightHours = aircraftLocationData[b].flightHours;
          aircraftGbteLocation.hex = aircraftLocationData[b].hex;
          aircraftGbteLocation.idClientInfo = aircraftLocationData[b].idClientInfo;
          aircraftGbteLocation.idDailyTotals = aircraftLocationData[b].idDailyTotals;
          aircraftGbteLocation.inAirFlag = aircraftLocationData[b].inAirFlag;
          aircraftGbteLocation.lastFlightFlag = aircraftLocationData[b].lastFlightFlag;
          aircraftGbteLocation.lastLat = latNum;
          aircraftGbteLocation.lastLng = lngNum;
          aircraftGbteLocation.lessor = aircraftLocationData[b].lessor;
          aircraftGbteLocation.registration = aircraftLocationData[b].registration;
          aircraftGbteLocation.serialNumber = aircraftLocationData[b].serialNumber;
          aircraftGbteLocation.startDateTime = aircraftLocationData[b].startDateTime;
          aircraftGbteLocation.stopDateTime = aircraftLocationData[b].stopDateTime;
          aircraftGbteLocation.time = aircraftLocationData[b].stopDateTime;
          aircraftGbteLocation.location = aircraftLocationData[b].arrivalAirport + ", " + aircraftLocationData[b].arrivalCity + ", " + aircraftLocationData[b].arrivalCountry;
          //location.location = __this.dailytotals[i][0].arrivalAirport+", "+__this.dailytotals[i][0].arrivalCity +", "+ __this.dailytotals[i][0].arrivalCountry;

          if(aircraftGbteLocation.location == "null, null, null" || aircraftGbteLocation.location == "undefined, undefined, undefined"){
            aircraftGbteLocation.location = aircraftLocationData[b].departureAirport + ", " + aircraftLocationData[b].departureCity + ", " + aircraftLocationData[b].departureCountry;
          }

          aircraftGbteLocation.techRep = aircraftLocationData[b].techRep;
          aircraftGbteLocation.uniqueFlightId = aircraftLocationData[b].uniqueFlightId;

          if (aircraftGbteLocation.assetType == "Engine") {
            aircraftGbteLocation.msn = "ESN";
          } else {
            aircraftGbteLocation.msn = "MSN";
          }

          this.aircraftGbteLocationArr.push(aircraftGbteLocation);
          this.captureChangeAllArr.push(aircraftGbteLocation);

        } else if (aircraftLocationData[b].assetType == 'Engine' && aircraftLocationData[b].inAirFlag == '0') {

          var latNum: number = +aircraftLocationData[b].lastLat;
          var lngNum: number = +aircraftLocationData[b].lastLng;

          engineGbteLocation.assetModelSeries = aircraftLocationData[b].assetModelSeries;
          engineGbteLocation.airborne = aircraftLocationData[b].airborne;
          engineGbteLocation.airportIncursion = aircraftLocationData[b].airportIncursion;
          engineGbteLocation.arrivalAirport = aircraftLocationData[b].arrivalAirport;
          engineGbteLocation.arrivalCity = aircraftLocationData[b].arrivalCity;
          engineGbteLocation.arrivalCountry = aircraftLocationData[b].arrivalCountry;
          engineGbteLocation.assetType = aircraftLocationData[b].assetType;
          engineGbteLocation.assetModelSeries = aircraftLocationData[b].assetModelSeries;
          engineGbteLocation.operator = aircraftLocationData[b].operator;
          engineGbteLocation.date = aircraftLocationData[b].date;
          engineGbteLocation.departureAirport = aircraftLocationData[b].departureAirport;
          engineGbteLocation.departureCity = aircraftLocationData[b].departureCity;
          engineGbteLocation.departureCountry = aircraftLocationData[b].departureCountry;
          engineGbteLocation.flightCycles = aircraftLocationData[b].flightCycles;
          engineGbteLocation.flightHours = aircraftLocationData[b].flightHours;
          engineGbteLocation.hex = aircraftLocationData[b].hex;
          engineGbteLocation.idClientInfo = aircraftLocationData[b].idClientInfo;
          engineGbteLocation.idDailyTotals = aircraftLocationData[b].idDailyTotals;
          engineGbteLocation.inAirFlag = aircraftLocationData[b].inAirFlag;
          engineGbteLocation.lastFlightFlag = aircraftLocationData[b].lastFlightFlag;
          engineGbteLocation.lastLat = latNum;
          engineGbteLocation.lastLng = lngNum;
          engineGbteLocation.lessor = aircraftLocationData[b].lessor;
          engineGbteLocation.registration = aircraftLocationData[b].registration;
          engineGbteLocation.serialNumber = aircraftLocationData[b].serialNumber;
          engineGbteLocation.startDateTime = aircraftLocationData[b].startDateTime;
          engineGbteLocation.stopDateTime = aircraftLocationData[b].stopDateTime;
          engineGbteLocation.time = aircraftLocationData[b].stopDateTime;
          engineGbteLocation.location = aircraftLocationData[b].arrivalAirport + ", " + aircraftLocationData[b].arrivalCity + ", " + aircraftLocationData[b].arrivalCountry;

          if(engineGbteLocation.location == "null, null, null"  || engineGbteLocation.location == "undefined, undefined, undefined"){
            engineGbteLocation.location = aircraftLocationData[b].departureAirport + ", " + aircraftLocationData[b].departureCity + ", " + aircraftLocationData[b].departureCountry;
          }

          engineGbteLocation.techRep = aircraftLocationData[b].techRep;
          engineGbteLocation.uniqueFlightId = aircraftLocationData[b].uniqueFlightId;

          if (engineGbteLocation.assetType == "Engine") {
            engineGbteLocation.msn = "ESN";
          } else {
            engineGbteLocation.msn = "MSN";
          }

          this.engineGbteLocationArr.push(engineGbteLocation);
          this.captureChangeAllArr.push(engineGbteLocation);
        } else {
          console.log('no Data on Airlines and Engines found');
        }

      }
      // console.log(this.engineGbteLocationArr);
      // console.log(this.aircraftInAirLocationArr);
      // console.log(this.engineInAirLocationArr);
      // console.log(this.aircraftGbteLocationArr);
      // find1
      let alrTableDataArr = [];
      let __this = this;
      // console.log("CAPTURE CHANGE AOG Initialisation")
      for (var i = 0; i < this.captureChangeAllArr.length; i++) {
        let alrTableData: any = {};
        alrTableData.serialNumber = this.captureChangeAllArr[i].serialNumber;
        alrTableData.registration = this.captureChangeAllArr[i].registration;
        alrTableData.assetType = this.captureChangeAllArr[i].assetType;
        alrTableData.assetModelSeries = this.captureChangeAllArr[i].assetModelSeries;
        alrTableData.operator = this.captureChangeAllArr[i].operator;
        alrTableData.time = this.captureChangeAllArr[i].time;
        alrTableData.location = this.captureChangeAllArr[i].location;
        if (__this.tableService.onGroundArrayFinal.hasOwnProperty(alrTableData.serialNumber)){
          alrTableData.daysOnGround = __this.tableService.onGroundArrayFinal[alrTableData.serialNumber].daysOnGround;
        }
        else {
          alrTableData.daysOnGround = '-';
        }
        alrTableDataArr.push(alrTableData)
      }
      // console.log("ALR TABLE ARRAYY");
      // console.log(alrTableDataArr);
      this.alrTableDataTwo = alrTableDataArr;
      this.alrTableLoader = false; // Hide the loader if no data is found
      // this.allOverlayData = this.allArr;
      // console.log(this.alrTableDataTwo);
      // console.log(this.alrTableData);
      // console.log(this.captureChangeAllArr);
      this.all = this.captureChangeAllArr;

      // Aircraft On Ground
      this.placeSeries = this.mapChart.series.push(new am4maps.MapImageSeries());
      this.placeSeries.name = 'Aircraft On Ground';
      this.placeSeries.fill = am4core.color("#00efa5");
      this.place = this.placeSeries.mapImages.template;

      this.place.nonScaling = true;
      this.place.propertyFields.latitude = "lastLat";
      this.place.propertyFields.longitude = "lastLng";
      this.placeSeries.data = this.aircraftGbteLocationArr;
      this.placeSeries.legendSymbol = {
        flag: "https://projectuniformmapsvg.s3-eu-west-1.amazonaws.com/AircraftOnGround.png"
      }

      var circle = this.place.createChild(am4core.Circle);
      circle.radius = 3;
      circle.fill = am4core.color("#00efa5");
      circle.stroke = am4core.color("#fff");
      circle.strokeWidth = 1;

      circle.tooltipText = "[bold]Reg: {registration}\n[bold]{msn}: {serialNumber}\n[bold]Type: {assetModelSeries}\n[bold]Operator: {operator}\n[bold]Airport: {location}\n[bold]Last Ping: {stopDateTime}"

      // Aircraft In Air
      this.placeSeries = this.mapChart.series.push(new am4maps.MapImageSeries());
      this.placeSeries.name = 'Aircraft In Air';
      this.placeSeries.fill = am4core.color("#0000FF");
      this.place = this.placeSeries.mapImages.template;
      this.place.nonScaling = true;
      this.place.propertyFields.latitude = "lastLat";
      this.place.propertyFields.longitude = "lastLng";
      this.placeSeries.data = this.aircraftInAirLocationArr;
      this.placeSeries.legendSymbol = {
        flag: "https://projectuniformmapsvg.s3-eu-west-1.amazonaws.com/AircraftInAir.png"
      }

      var circle = this.place.createChild(am4core.Circle);
      circle.radius = 3;
      circle.fill = am4core.color("#0000FF");
      circle.stroke = am4core.color("#fff");
      circle.strokeWidth = 1;
      circle.tooltipText = "[bold]Reg: {registration}\n[bold]{msn}: {serialNumber}\n[bold]Type: {assetModelSeries}\n[bold]Operator: {operator}\n[bold]Dep. Airport: {departureAirport}, [bold]{departureCity}, [bold]{departureCountry}\n[bold]Position: {lastLat}, {lastLng}\n[bold]Last Ping: {stopDateTime}"

      // Engine on Ground
      this.placeSeries = this.mapChart.series.push(new am4maps.MapImageSeries());
      this.placeSeries.name = 'Engine On Ground';
      this.placeSeries.fill = am4core.color("#00efa5");
      this.place = this.placeSeries.mapImages.template;

      this.place.nonScaling = true;
      this.place.propertyFields.latitude = "lastLat";
      this.place.propertyFields.longitude = "lastLng";
      this.placeSeries.data = this.engineGbteLocationArr;
      this.placeSeries.legendSymbol = {
        flag: "https://projectuniformmapsvg.s3-eu-west-1.amazonaws.com/EngineOnGround.png"
      }

      var triangle = this.place.createChild(am4core.Triangle);
      triangle.fill = am4core.color("#00efa5");
      triangle.stroke = am4core.color("#fff");
      triangle.strokeWidth = 1;
      triangle.width = 8;
      triangle.height = 8;
      triangle.tooltipText = "[bold]Reg: {registration}\n[bold]{msn}: {serialNumber}\n[bold]Type: {assetModelSeries}\n[bold]Operator: {operator}\n[bold]Airport: {location}\n[bold]Last Ping: {stopDateTime}"

      // Engine in Air
      this.placeSeries = this.mapChart.series.push(new am4maps.MapImageSeries());
      this.placeSeries.name = 'Engine In Air';
      this.placeSeries.fill = am4core.color("#0000FF");
      this.place = this.placeSeries.mapImages.template;

      this.place.nonScaling = true;
      this.place.propertyFields.latitude = "lastLat";
      this.place.propertyFields.longitude = "lastLng";
      this.placeSeries.data = this.engineInAirLocationArr;
      this.placeSeries.legendSymbol = {
        flag: "https://projectuniformmapsvg.s3-eu-west-1.amazonaws.com/EngineInAir.png"
      }

      var triangle = this.place.createChild(am4core.Triangle);
      triangle.fill = am4core.color("#0000FF");
      triangle.stroke = am4core.color("#fff");
      triangle.strokeWidth = 1;
      triangle.width = 8;
      triangle.height = 8;
      triangle.tooltipText = "[bold]Reg: {registration}\n[bold]{msn}: {serialNumber}\n[bold]Type: {assetModelSeries}\n[bold]Operator: {operator}\n[bold]Dep. Airport: {departureAirport}, [bold]{departureCity}, [bold]{departureCountry}\n[bold]Position: {lastLat}, {lastLng}\n[bold]Last Ping: {stopDateTime}";
  }

  ngOnInit() {
    var __this = this;
    var locationArr = [];
    var allArr = [];
    var aircraftInAirLocationArr = [];
    var engineInAirLocationArr = [];
    var aircraftGbteLocationArr = [];
    var engineGbteLocationArr = [];
    //console.log(this.table2);
    //console.log(this.row);

    this.setDateTime()
      .then(() => {
        this.getClientDetails().then(function (res) {
          return __this.serialNumber;
        }).then(function (serialNumber) {
          return __this.getALR(serialNumber);
        }).then(function () {
          // console.log("This.dailytotals");
          // console.log(__this.dailytotals);
          // console.log(__this.dailytotals.length);
          var aircraftLocationData = __this.dailytotals;
          // console.log(aircraftLocationData[0].inAirFlag);
          // console.log(aircraftLocationData.inAirFlag);

          var countAircraftAssets = 0;
          var countEngineAssets = 0;
          // Count of Engine Assets and Aircraft Assets
          //console.log(aircraftLocationData.length);

          for (var a = 0; a < aircraftLocationData.length; a++) {
            //console.log(aircraftLocationData.length);
            if (aircraftLocationData[a][0].assetType == 'Aircraft') {
              countAircraftAssets++;
              // console.log('Count Aircraft');
              // console.log(countAircraftAssets);
            } else {
              countEngineAssets++;
              // console.log('Count Engine Assets');
              // console.log(countEngineAssets);
            }
          }

          // top title for Aircraft Location Report
          var title = __this.mapChart.titles.create();
          title.fontSize = "1em";
          title.text = "Asset Locations: \n" + countAircraftAssets + " Aircraft \n" + countEngineAssets + " Engines ";
          title.align = "left";
          title.horizontalCenter = "left";
          title.marginLeft = 20;
          title.paddingBottom = 10;
          title.fill = am4core.color("#ffffff");
          title.y = 20;

          // console.log(aircraftLocationData)
          // console.log(__this.dailytotals.length);

          for (var b = 0; b < aircraftLocationData.length; b++) {
            var aircraftInAirLocation: any = {};
            var engineInAirLocation: any = {};
            var aircraftGbteLocation: any = {};
            var engineGbteLocation: any = {};

            //AirCraft Asset & inAirFlag = 1
            if (aircraftLocationData[b][0].assetType == 'Aircraft' && aircraftLocationData[b][0].inAirFlag == '1') {

              var latNum: number = +aircraftLocationData[b][0].lastLat;
              var lngNum: number = +aircraftLocationData[b][0].lastLng;

              aircraftInAirLocation.assetModelSeries = aircraftLocationData[b][0].assetModelSeries;
              aircraftInAirLocation.airborne = aircraftLocationData[b][0].airborne;
              aircraftInAirLocation.airportIncursion = aircraftLocationData[b][0].airportIncursion;
              aircraftInAirLocation.arrivalAirport = aircraftLocationData[b][0].arrivalAirport;
              aircraftInAirLocation.arrivalCity = aircraftLocationData[b][0].arrivalCity;
              aircraftInAirLocation.arrivalCountry = aircraftLocationData[b][0].arrivalCountry;
              aircraftInAirLocation.assetType = aircraftLocationData[b][0].assetType;
              aircraftInAirLocation.assetModelSeries = aircraftLocationData[b][0].assetModelSeries;
              aircraftInAirLocation.operator = aircraftLocationData[b][0].operator;
              aircraftInAirLocation.date = aircraftLocationData[b][0].date;
              aircraftInAirLocation.departureAirport = aircraftLocationData[b][0].departureAirport;
              aircraftInAirLocation.departureCity = aircraftLocationData[b][0].departureCity;
              aircraftInAirLocation.departureCountry = aircraftLocationData[b][0].departureCountry;
              aircraftInAirLocation.flightCycles = aircraftLocationData[b][0].flightCycles;
              aircraftInAirLocation.flightHours = aircraftLocationData[b][0].flightHours;
              aircraftInAirLocation.hex = aircraftLocationData[b][0].hex;
              aircraftInAirLocation.idClientInfo = aircraftLocationData[b][0].idClientInfo;
              aircraftInAirLocation.idDailyTotals = aircraftLocationData[b][0].idDailyTotals;
              aircraftInAirLocation.inAirFlag = aircraftLocationData[b][0].inAirFlag;
              aircraftInAirLocation.lastFlightFlag = aircraftLocationData[b][0].lastFlightFlag;
              aircraftInAirLocation.lastLat = latNum;
              aircraftInAirLocation.lastLng = lngNum;
              aircraftInAirLocation.lessor = aircraftLocationData[b][0].lessor;
              aircraftInAirLocation.registration = aircraftLocationData[b][0].registration;
              aircraftInAirLocation.serialNumber = aircraftLocationData[b][0].serialNumber;
              aircraftInAirLocation.startDateTime = aircraftLocationData[b][0].startDateTime;
              aircraftInAirLocation.stopDateTime = aircraftLocationData[b][0].stopDateTime;
              aircraftInAirLocation.time = aircraftLocationData[b][0].stopDateTime;
              aircraftInAirLocation.location = "IN AIR";

              aircraftInAirLocation.techRep = aircraftLocationData[b][0].techRep;
              aircraftInAirLocation.uniqueFlightId = aircraftLocationData[b][0].uniqueFlightId;

              if (aircraftInAirLocation.assetType == "Engine") {
                aircraftInAirLocation.msn = "ESN";
              } else {
                aircraftInAirLocation.msn = "MSN";
              }

              aircraftInAirLocationArr.push(aircraftInAirLocation);
              //console.log('Aircr`ft In Air');
             // console.log(aircraftInAirLocationArr.length);
              allArr.push(aircraftInAirLocation);
              //console.log(allArr);
              //console.log(aircraftInAirLocationArr);

            } else if (aircraftLocationData[b][0].assetType == 'Engine' && aircraftLocationData[b][0].inAirFlag == '1') {

              var latNum: number = +aircraftLocationData[b][0].lastLat;
              var lngNum: number = +aircraftLocationData[b][0].lastLng;

              engineInAirLocation.assetModelSeries = aircraftLocationData[b][0].assetModelSeries;
              engineInAirLocation.airborne = aircraftLocationData[b][0].airborne;
              engineInAirLocation.airportIncursion = aircraftLocationData[b][0].airportIncursion;
              engineInAirLocation.arrivalAirport = aircraftLocationData[b][0].arrivalAirport;
              engineInAirLocation.arrivalCity = aircraftLocationData[b][0].arrivalCity;
              engineInAirLocation.arrivalCountry = aircraftLocationData[b][0].arrivalCountry;
              engineInAirLocation.assetType = aircraftLocationData[b][0].assetType;
              engineInAirLocation.assetModelSeries = aircraftLocationData[b][0].assetModelSeries;
              engineInAirLocation.operator = aircraftLocationData[b][0].operator;
              engineInAirLocation.date = aircraftLocationData[b][0].date;
              engineInAirLocation.departureAirport = aircraftLocationData[b][0].departureAirport;
              engineInAirLocation.departureCity = aircraftLocationData[b][0].departureCity;
              engineInAirLocation.departureCountry = aircraftLocationData[b][0].departureCountry;
              engineInAirLocation.flightCycles = aircraftLocationData[b][0].flightCycles;
              engineInAirLocation.flightHours = aircraftLocationData[b][0].flightHours;
              engineInAirLocation.hex = aircraftLocationData[b][0].hex;
              engineInAirLocation.idClientInfo = aircraftLocationData[b][0].idClientInfo;
              engineInAirLocation.idDailyTotals = aircraftLocationData[b][0].idDailyTotals;
              engineInAirLocation.inAirFlag = aircraftLocationData[b][0].inAirFlag;
              engineInAirLocation.lastFlightFlag = aircraftLocationData[b][0].lastFlightFlag;
              engineInAirLocation.lastLat = latNum;
              engineInAirLocation.lastLng = lngNum;
              engineInAirLocation.lessor = aircraftLocationData[b][0].lessor;
              engineInAirLocation.registration = aircraftLocationData[b][0].registration;
              engineInAirLocation.serialNumber = aircraftLocationData[b][0].serialNumber;
              engineInAirLocation.startDateTime = aircraftLocationData[b][0].startDateTime;
              engineInAirLocation.stopDateTime = aircraftLocationData[b][0].stopDateTime;
              engineInAirLocation.time = aircraftLocationData[b][0].stopDateTime;
              engineInAirLocation.location = "IN AIR";

              engineInAirLocation.techRep = aircraftLocationData[b][0].techRep;
              engineInAirLocation.uniqueFlightId = aircraftLocationData[b][0].uniqueFlightId;

              if (engineInAirLocation.assetType == "Engine") {
                engineInAirLocation.msn = "ESN";
              } else {
                engineInAirLocation.msn = "MSN";
              }

              engineInAirLocationArr.push(engineInAirLocation);
              // console.log('Engine In Air');
              // console.log(engineInAirLocationArr.length);
              allArr.push(engineInAirLocation);
              // console.log(allArr);

            } else if (aircraftLocationData[b][0].assetType == 'Aircraft' && aircraftLocationData[b][0].inAirFlag == '0') {

              var latNum: number = +aircraftLocationData[b][0].lastLat;
              var lngNum: number = +aircraftLocationData[b][0].lastLng;

              aircraftGbteLocation.assetModelSeries = aircraftLocationData[b][0].assetModelSeries;
              aircraftGbteLocation.airborne = aircraftLocationData[b][0].airborne;
              aircraftGbteLocation.airportIncursion = aircraftLocationData[b][0].airportIncursion;
              aircraftGbteLocation.arrivalAirport = aircraftLocationData[b][0].arrivalAirport;
              aircraftGbteLocation.arrivalCity = aircraftLocationData[b][0].arrivalCity;
              aircraftGbteLocation.arrivalCountry = aircraftLocationData[b][0].arrivalCountry;
              aircraftGbteLocation.assetType = aircraftLocationData[b][0].assetType;
              aircraftGbteLocation.assetModelSeries = aircraftLocationData[b][0].assetModelSeries;
              aircraftGbteLocation.operator = aircraftLocationData[b][0].operator;
              aircraftGbteLocation.date = aircraftLocationData[b][0].date;
              aircraftGbteLocation.departureAirport = aircraftLocationData[b][0].departureAirport;
              aircraftGbteLocation.departureCity = aircraftLocationData[b][0].departureCity;
              aircraftGbteLocation.departureCountry = aircraftLocationData[b][0].departureCountry;
              aircraftGbteLocation.flightCycles = aircraftLocationData[b][0].flightCycles;
              aircraftGbteLocation.flightHours = aircraftLocationData[b][0].flightHours;
              aircraftGbteLocation.hex = aircraftLocationData[b][0].hex;
              aircraftGbteLocation.idClientInfo = aircraftLocationData[b][0].idClientInfo;
              aircraftGbteLocation.idDailyTotals = aircraftLocationData[b][0].idDailyTotals;
              aircraftGbteLocation.inAirFlag = aircraftLocationData[b][0].inAirFlag;
              aircraftGbteLocation.lastFlightFlag = aircraftLocationData[b][0].lastFlightFlag;
              aircraftGbteLocation.lastLat = latNum;
              aircraftGbteLocation.lastLng = lngNum;
              aircraftGbteLocation.lessor = aircraftLocationData[b][0].lessor;
              aircraftGbteLocation.registration = aircraftLocationData[b][0].registration;
              aircraftGbteLocation.serialNumber = aircraftLocationData[b][0].serialNumber;
              aircraftGbteLocation.startDateTime = aircraftLocationData[b][0].startDateTime;
              aircraftGbteLocation.stopDateTime = aircraftLocationData[b][0].stopDateTime;
              aircraftGbteLocation.time = aircraftLocationData[b][0].stopDateTime;
              aircraftGbteLocation.location = aircraftLocationData[b][0].arrivalAirport + ", " + aircraftLocationData[b][0].arrivalCity + ", " + aircraftLocationData[b][0].arrivalCountry;
              //location.location = __this.dailytotals[i][0].arrivalAirport+", "+__this.dailytotals[i][0].arrivalCity +", "+ __this.dailytotals[i][0].arrivalCountry;

              if(aircraftGbteLocation.location == "null, null, null"){
                aircraftGbteLocation.location = aircraftLocationData[b][0].departureAirport + ", " + aircraftLocationData[b][0].departureCity + ", " + aircraftLocationData[b][0].departureCountry;
              }

              aircraftGbteLocation.techRep = aircraftLocationData[b][0].techRep;
              aircraftGbteLocation.uniqueFlightId = aircraftLocationData[b][0].uniqueFlightId;

              if (aircraftGbteLocation.assetType == "Engine") {
                aircraftGbteLocation.msn = "ESN";
              } else {
                aircraftGbteLocation.msn = "MSN";
              }

              aircraftGbteLocationArr.push(aircraftGbteLocation);

              // console.log('Aircraft On Ground');
              // console.log(aircraftGbteLocationArr);
              // console.log(aircraftGbteLocationArr.length);
              allArr.push(aircraftGbteLocation);
              // console.log(allArr);


            } else if (aircraftLocationData[b][0].assetType == 'Engine' && aircraftLocationData[b][0].inAirFlag == '0') {

              var latNum: number = +aircraftLocationData[b][0].lastLat;
              var lngNum: number = +aircraftLocationData[b][0].lastLng;

              engineGbteLocation.assetModelSeries = aircraftLocationData[b][0].assetModelSeries;
              engineGbteLocation.airborne = aircraftLocationData[b][0].airborne;
              engineGbteLocation.airportIncursion = aircraftLocationData[b][0].airportIncursion;
              engineGbteLocation.arrivalAirport = aircraftLocationData[b][0].arrivalAirport;
              engineGbteLocation.arrivalCity = aircraftLocationData[b][0].arrivalCity;
              engineGbteLocation.arrivalCountry = aircraftLocationData[b][0].arrivalCountry;
              engineGbteLocation.assetType = aircraftLocationData[b][0].assetType;
              engineGbteLocation.assetModelSeries = aircraftLocationData[b][0].assetModelSeries;
              engineGbteLocation.operator = aircraftLocationData[b][0].operator;
              engineGbteLocation.date = aircraftLocationData[b][0].date;
              engineGbteLocation.departureAirport = aircraftLocationData[b][0].departureAirport;
              engineGbteLocation.departureCity = aircraftLocationData[b][0].departureCity;
              engineGbteLocation.departureCountry = aircraftLocationData[b][0].departureCountry;
              engineGbteLocation.flightCycles = aircraftLocationData[b][0].flightCycles;
              engineGbteLocation.flightHours = aircraftLocationData[b][0].flightHours;
              engineGbteLocation.hex = aircraftLocationData[b][0].hex;
              engineGbteLocation.idClientInfo = aircraftLocationData[b][0].idClientInfo;
              engineGbteLocation.idDailyTotals = aircraftLocationData[b][0].idDailyTotals;
              engineGbteLocation.inAirFlag = aircraftLocationData[b][0].inAirFlag;
              engineGbteLocation.lastFlightFlag = aircraftLocationData[b][0].lastFlightFlag;
              engineGbteLocation.lastLat = latNum;
              engineGbteLocation.lastLng = lngNum;
              engineGbteLocation.lessor = aircraftLocationData[b][0].lessor;
              engineGbteLocation.registration = aircraftLocationData[b][0].registration;
              engineGbteLocation.serialNumber = aircraftLocationData[b][0].serialNumber;
              engineGbteLocation.startDateTime = aircraftLocationData[b][0].startDateTime;
              engineGbteLocation.stopDateTime = aircraftLocationData[b][0].stopDateTime;
              engineGbteLocation.time = aircraftLocationData[b][0].stopDateTime;
              engineGbteLocation.location = aircraftLocationData[b][0].arrivalAirport + ", " + aircraftLocationData[b][0].arrivalCity + ", " + aircraftLocationData[b][0].arrivalCountry;

              if(engineGbteLocation.location == "null, null, null"){
                engineGbteLocation.location = aircraftLocationData[b][0].departureAirport + ", " + aircraftLocationData[b][0].departureCity + ", " + aircraftLocationData[b][0].departureCountry;
              }

              engineGbteLocation.techRep = aircraftLocationData[b][0].techRep;
              engineGbteLocation.uniqueFlightId = aircraftLocationData[b][0].uniqueFlightId;

              if (engineGbteLocation.assetType == "Engine") {
                engineGbteLocation.msn = "ESN";
              } else {
                engineGbteLocation.msn = "MSN";
              }

              engineGbteLocationArr.push(engineGbteLocation);
              // console.log('Engine On ground');
              // console.log(engineGbteLocationArr);
              // console.log(engineGbteLocationArr.length);
              allArr.push(engineGbteLocation);
             // console.log(allArr);
            } else {
              console.log('no Data on Airlines and Engines found');
            }
          }
          // Remove the loader
          __this.fleetActivityDailyLoaded = true
          __this.fleetActivityMonthlyLoaded = true

          let alrTableDataArr = [];

          //find1

          for (var i = 0; i < allArr.length; i++) {
            //console.log("ALL ARR LENGTH");
            //console.log(allArr.length);
            let alrTableData: any = {};
            //console.log("ngOnInit AOG Initialisation");

            alrTableData.serialNumber = allArr[i].serialNumber;
            alrTableData.registration = allArr[i].registration;
            alrTableData.assetType = allArr[i].assetType;
            alrTableData.assetModelSeries = allArr[i].assetModelSeries;
            alrTableData.operator = allArr[i].operator;
            alrTableData.time = allArr[i].time;
            alrTableData.location = allArr[i].location;
            if (__this.tableService.onGroundArrayFinal.hasOwnProperty(alrTableData.serialNumber)){
              alrTableData.daysOnGround = __this.tableService.onGroundArrayFinal[alrTableData.serialNumber].daysOnGround;
            }
            else {
              alrTableData.daysOnGround = '-';
            }
            alrTableDataArr.push(alrTableData);
          }
          //console.log("ALR TABLE ARRAYY");
          //console.log(alrTableDataArr);
          __this.alrTableData = alrTableDataArr;
          __this.allOverlayData = allArr;
          __this.alrTableDataTwo = alrTableDataArr;
          __this.alrTableLoader = false; // Hide the loader
          // console.log(alrTableDataArr)
          // console.log(__this.alrTableDataTwo);
          //console.log(allArr)
          __this.all = allArr;
        }).then(function () {
          //Aircraft On Ground
          __this.placeSeries = __this.mapChart.series.push(new am4maps.MapImageSeries());
          __this.placeSeries.name = 'Aircraft On Ground';
          __this.placeSeries.fill = am4core.color("#00efa5");
          __this.place = __this.placeSeries.mapImages.template;

          __this.place.nonScaling = true;
          __this.place.propertyFields.latitude = "lastLat";
          __this.place.propertyFields.longitude = "lastLng";
          __this.placeSeries.data = aircraftGbteLocationArr;
          __this.placeSeries.legendSymbol = {
            flag: "https://projectuniformmapsvg.s3-eu-west-1.amazonaws.com/AircraftOnGround.png"
          }

          var circle = __this.place.createChild(am4core.Circle);
          circle.radius = 3;
          circle.fill = am4core.color("#00efa5");
          circle.stroke = am4core.color("#fff");
          circle.strokeWidth = 1;
          circle.tooltipText = "[bold]Reg: {registration}\n[bold]{msn}: {serialNumber}\n[bold]Type: {assetModelSeries}\n[bold]Operator: {operator}\n[bold]Airport: {location}\n[bold]Last Ping: {stopDateTime}"

          // Aircraft In Air
          __this.placeSeries = __this.mapChart.series.push(new am4maps.MapImageSeries());
          __this.placeSeries.name = 'Aircraft In Air';
          __this.placeSeries.fill = am4core.color("#0000FF");
          __this.place = __this.placeSeries.mapImages.template;
          __this.place.nonScaling = true;
          __this.place.propertyFields.latitude = "lastLat";
          __this.place.propertyFields.longitude = "lastLng";
          //__this.placeSeries.data = aircraftInAirLocationArr;
          __this.placeSeries.data = aircraftInAirLocationArr;
          __this.placeSeries.legendSymbol = {
            flag: "https://projectuniformmapsvg.s3-eu-west-1.amazonaws.com/AircraftInAir.png"
          }

          var circle = __this.place.createChild(am4core.Circle);
          circle.radius = 3;
          circle.fill = am4core.color("#0000FF");
          circle.stroke = am4core.color("#fff");
          circle.strokeWidth = 1;
          circle.tooltipText = "[bold]Reg: {registration}\n[bold]{msn}: {serialNumber}\n[bold]Type: {assetModelSeries}\n[bold]Operator: {operator}\n[bold]Dep. Airport: {departureAirport}, [bold]{departureCity}, [bold]{departureCountry}\n[bold]Position: {lastLat}, {lastLng}\n[bold]Last Ping: {stopDateTime}"

          // Engine on Ground
          __this.placeSeries = __this.mapChart.series.push(new am4maps.MapImageSeries());
          __this.placeSeries.name = 'Engine On Ground';
          __this.placeSeries.fill = am4core.color("#00efa5");
          __this.place = __this.placeSeries.mapImages.template;

          __this.place.nonScaling = true;
          __this.place.propertyFields.latitude = "lastLat";
          __this.place.propertyFields.longitude = "lastLng";
          __this.placeSeries.data = engineGbteLocationArr;
          __this.placeSeries.legendSymbol = {
            flag: "https://projectuniformmapsvg.s3-eu-west-1.amazonaws.com/EngineOnGround.png"
          }

          var triangle = __this.place.createChild(am4core.Triangle);
          triangle.fill = am4core.color("#00efa5");
          triangle.stroke = am4core.color("#fff");
          triangle.strokeWidth = 1;
          triangle.width = 8;
          triangle.height = 8;
          triangle.tooltipText = "[bold]Reg: {registration}\n[bold]{msn}: {serialNumber}\n[bold]Type: {assetModelSeries}\n[bold]Operator: {operator}\n[bold]Airport: {location}\n[bold]Last Ping: {stopDateTime}"

          // Engine in Air
          __this.placeSeries = __this.mapChart.series.push(new am4maps.MapImageSeries());
          __this.placeSeries.name = 'Engine In Air';
          __this.placeSeries.fill = am4core.color("#0000FF");
          __this.place = __this.placeSeries.mapImages.template;
          __this.place.nonScaling = true;
          __this.place.propertyFields.latitude = "lastLat";
          __this.place.propertyFields.longitude = "lastLng";
          __this.placeSeries.data = engineInAirLocationArr;
          __this.placeSeries.legendSymbol = {
            flag: "https://projectuniformmapsvg.s3-eu-west-1.amazonaws.com/EngineInAir.png"
          }

          var triangle = __this.place.createChild(am4core.Triangle);
          triangle.fill = am4core.color("#0000FF");
          triangle.stroke = am4core.color("#fff");
          triangle.strokeWidth = 1;
          triangle.width = 8;
          triangle.height = 8;
          triangle.tooltipText = "[bold]Reg: {registration}\n[bold]{msn}: {serialNumber}\n[bold]Type: {assetModelSeries}\n[bold]Operator: {operator}\n[bold]Dep. Airport: {departureAirport}, [bold]{departureCity}, [bold]{departureCountry}\n[bold]Position: {lastLat}, {lastLng}\n[bold]Last Ping: {stopDateTime}";

          __this.mapChart.legend = new am4maps.Legend();
          __this.mapChart.legend.useDefaultMarker = true;

          var marker = __this.mapChart.legend.markers.template;
          marker.disposeChildren();
          marker.width = 40;
          marker.height = 40;

          var flag = marker.createChild(am4core.Image);
          flag.width = 25;
          flag.height = 25;
          flag.adapter.add("href", function (href, target) {
            if (target.dataItem && target.dataItem.dataContext && target.dataItem.dataContext.legendSymbol) {
              return target.dataItem.dataContext.legendSymbol.flag;
            }
            else {
              return href;
            }
          });
          //console.log(__this.mapChart.series);
          //console.log(__this.mapChart.series.length)
          var counts = __this.aircraftOnGroundGragph();
          return counts;
        }).then(function (counts) {
          // __this.pieChart(counts); Commented by Sanket
        })
      })

    this.container = am4core.create("chartdiv", am4core.Container);
    this.container.layout = "vertical";
    this.container.width = am4core.percent(100);
    this.container.height = am4core.percent(100);
    this.container.background = new am4core.RoundedRectangle();
    this.container.background.fill = am4core.color("#000000");
    this.container.background.cornerRadius(30, 30, 30, 30)
    this.container.background.fillOpacity = 0.25;
    this.container.tooltip = new am4core.Tooltip();

    // map chart
    this.mapChart = this.container.createChild(am4maps.MapChart);
    this.mapChart.geodata = am4geodata_worldLow;
    this.mapChart.projection = new am4maps.projections.Miller();
    this.mapChart.seriesContainer.draggable = true;
    this.mapChart.zoomControl = new am4maps.ZoomControl();
    this.mapChart.zoomControl.align = "right";
    this.mapChart.zoomControl.marginRight = 15;
    this.mapChart.zoomControl.valign = "middle";

    var polygonSeries = this.mapChart.series.push(new am4maps.MapPolygonSeries());
    polygonSeries.hiddenInLegend = true;
    polygonSeries.useGeodata = true;
    polygonSeries.exclude = ["AQ"]; // Antarctica is excluded in non-globe projection
    this.mapChart.homeGeoPoint = { longitude: 0, latitude: 15 };
    this.mapChart.homeZoomLevel = 0;

    // switch between map and globe
    var mapGlobeSwitch = this.mapChart.createChild(am4core.SwitchButton);
    mapGlobeSwitch.align = "right"
    mapGlobeSwitch.y = 15;
    mapGlobeSwitch.leftLabel.text = "Map";
    mapGlobeSwitch.leftLabel.fill = am4core.color("#ffffff");
    mapGlobeSwitch.rightLabel.fill = am4core.color("#ffffff");
    mapGlobeSwitch.rightLabel.text = "Globe";
    mapGlobeSwitch.verticalCenter = "top";

    mapGlobeSwitch.events.on("toggled", function () {
      if (mapGlobeSwitch.isActive) {
        __this.mapChart.projection = new am4maps.projections.Orthographic;
        __this.mapChart.backgroundSeries.show();
        __this.mapChart.panBehavior = "rotateLongLat";
        polygonSeries.exclude = [];
      } else {
        __this.mapChart.projection = new am4maps.projections.Miller;
        __this.mapChart.panBehavior = "move";
        polygonSeries.exclude = ["AQ"];
        polygonSeries.data = locationArr;
        polygonSeries.exclude = ["AQ"];
      }
    })

  }// End of ngOnInit

  async setDateTime() {
    //console.log("Setting date/time of Dashboard...");
    var __this = this;
    await this.httpClient.get(config.apiUrl + '/uniform/latestday')
      .toPromise()
      .then((response: any) => {
        if (response.response === 200) {
          let latestDay = response.data;
          // console.log("latestDay");
          // console.log(latestDay);
          //console.log(latestDay[0].date);
          let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
          __this.monthEnd = latestDay[0].date;
          __this.day = latestDay[0].date.split(' ')[0]; //'31-08-2020'
          // console.log('setDateTime', __this.day);
          let numOfMonth = latestDay[0].date.split('-')[1];
          let year = latestDay[0].date.split('-')[0];
          //console.log("numOfMonth: " + numOfMonth);
          let month = months[numOfMonth - 1];
          // console.log("month: " + month);
          // console.log("year: "+year);
          __this.month = month + " " + year;
          __this.monthYear = year + '-' + numOfMonth
        }
      })
  }

  getClientDetails() {
    //console.log('Getting client details');
    let __this = this;
    return this.httpClient.get(config.apiUrl + '/uniform/clientdetails/undefined')
      .toPromise()
      .then((response: any) => {
        //console.log("clientDetails");
        //console.log(response);
        if (response.response === 200) {
          let clientDetails = response.data;
          var serialNumber = []
          for (var i = 0; i < clientDetails.length; i++) {
            // console.log(i);
            //hex.push(clientDetails[i].hex)
            serialNumber.push(clientDetails[i].serialNumber)
          }
           // console.log(serialNumber);
          __this.serialNumber = serialNumber;
        }
      });
  }

  aircraftOnGroundGragph() {
    //console.log("Calculating aircraftOnGroundGragph...");
    //console.log(this.all);
    // var dates = []
    // for (var i = 0; i < this.all.length; i++) {
    //   dates.push(this.all[i].date)
    // }
    var counts = {};
    // dates.forEach(function (x) { counts[x] = (counts[x] || 0) + 1; });
    // console.log('---- Date ----')
    // console.log(counts);
    /*(console.log("2020-05-31 23:59:59");
    console.log(counts["2020-05-31 23:59:59"]);
    console.log("2020-06-30 23:59:59");
    console.log(counts["2020-06-30 23:59:59"]);*/
    return counts;
  }

  getALR(serialNumber) {
    var __this = this;
    // console.log("Getting ALR...");
    return this.httpClient.post(config.apiUrl + '/uniform/report/aircraftlocation', serialNumber)
      .toPromise()
      .then((response: any) => {
        if (response.response === 200) {
          let res = response.data
          __this.allAssets = res.length;
          for (var k = 0; k < res.length; k++) {
            if (res[k].length > 0) {
              __this.dailytotals.push(res[k]);
              // console.log('hex');
              // console.log(__this.dailytotals);
            }
            //sort by serial number ascending
            __this.dailytotals.sort((a, b) => a[0].serialNumber - b[0].serialNumber);
            //  console.log("ALR returned 1 Daily Totals");
            //  console.log(__this.dailytotals);
          }
        }
      }).then(() => {
        // console.log('Inside getAOG: Date', __this.day)
        return __this.tableService.getAOG(serialNumber, __this.day, __this.monthYear);
      }).then(() => {
        __this.fleetActivityDayAircraft = __this.tableService.fleetActivityDayAircraft
        __this.fleetActivityMonthAircraft = __this.tableService.fleetActivityMonthAircraft
        __this.fleetActivityDayEngine = __this.tableService.fleetActivityDayEngine
        __this.fleetActivityMonthEngine = __this.tableService.fleetActivityMonthEngine
       // console.log("AOG COMPLETED")
      })
  }

  exportToExcel2() {
    //console.log('exporting to xls 2');
    // console.log(this.table2);

    const ws: xlsx.WorkSheet =
      xlsx.utils.table_to_sheet(this.table2.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'AircraftLocationReport.xlsx');
  }
  exportToCsv() {
    var filename = "AircraftLocationReport"
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      filename: filename,
    };
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(this.alrTableDataTwo);
  }
  pieChart(counts) {
    /*console.log("in piechart");
    console.log(counts);
    console.log("1st chart");
    console.log(counts[this.monthEnd.toString()]);
    console.log(this.allAssets - counts[this.monthEnd]);
    console.log(this.allAssets);
    console.log(counts[this.monthEnd]);*/
    let __this = this
    //1st  chart
    var chart1 = new Highcharts.Chart({
      chart: {
        renderTo: 'container1',
        type: 'pie',
        height: 200,
        width: 200,
        events: {
          load: function () {
            __this.fleetActivityDailyLoaded = true
          }
        },
      },
      title: {
        text: '<p class="card-title">Fleet Activity ' + this.day + '</p>'
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false
          },
          showInLegend: false
        }
      },
      credits: {
        enabled: false
      },
      exporting: {
        enabled: false // hide button
      },
      series: [{
        innerSize: '50%',
        name: 'Flown Today',
        type: undefined,
        data: [
          { name: 'Flown Today', y: counts[this.monthEnd.toString()], color: 'blue' },
          { name: 'Not Flown Today', y: this.allAssets - counts[this.monthEnd], color: '#e1e6e5' }
        ]
      }],
    });

   /* console.log("2nd chart");
    console.log(chart2isFlown);
    console.log(chart2isNotFlown);
    console.log(this.allAssets);
    console.log(this.dailytotals.length);*/

    var total = 0;
    for (var property in counts) {
      // console.log("property: " +property);
      // console.log("this.monthEnd: " +this.monthEnd);
      if (property.split("-")[0] == this.monthEnd.split("-")[0] && property.split("-")[1] == this.monthEnd.split("-")[1]) {
        // console.log("match");
        // console.log("property: " +property);
        // console.log("this.monthEnd: " +this.monthEnd);
        total += counts[property];
      }
    }
    // console.log("total");
    // console.log(total);
    var chart2isFlown = counts[this.monthEnd]
    var chart2isNotFlown = this.allAssets - total;
    //console.log("chart2isNotFlown");
    //console.log(chart2isNotFlown);

    //2nd chart
    new Highcharts.Chart({
      chart: {
        renderTo: 'container2',
        type: 'pie',
        height: 200,
        width: 200,
        events: {
          load: function () {
            __this.fleetActivityMonthlyLoaded = true
          }
        },
      },
      title: {
        text: 'Fleet Activity \n' + this.month
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },
      credits: {
        enabled: false
      },
      exporting: {
        enabled: false // hide button
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false
          },
          showInLegend: false
        }
      },
      series: [{
        innerSize: '50%',
        type: undefined,
        name: 'Activity',
        data: [
          { name: 'Activity', y: total, color: 'blue' },
          { name: 'On ground', y: chart2isNotFlown, color: '#e1e6e5' }
        ]
      }]
    });

    //3rd chart
    /* new Highcharts.Chart({
      chart: {
        renderTo: 'container3',
        type: 'pie',
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        height: 200,
        width: 200
      },
      title: {
        text: 'Geo-incursion Status'
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },
      credits: {
        enabled: false
      },
      exporting: {
        enabled: false // hide button
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false
          },
          showInLegend: false
        }
      },
      subtitle: {
        text: ''
      },

      series: [{
        // innerSize: '50%',
        type: undefined,
        name: '',
        data: [
          { name: 'Incursion', y: 0, color: 'orange' },
          { name: 'Upcoming Feature', y: 70, color: 'grey' }
        ]
      }]
    }); */
  }
}
