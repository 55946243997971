import { Component, OnInit,ViewChild, ElementRef } from '@angular/core';
import {TableserviceService} from '../../services/tableservice.service'
import { map } from 'rxjs/operators';
import * as Highcharts from 'highcharts';
import * as Highstock from "highcharts/highstock";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
import { HttpClient } from '@angular/common/http';
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as xlsx from 'xlsx';

@Component({
  selector: 'app-daily-info',
  templateUrl: './daily-info.component.html',
  styleUrls: ['./daily-info.component.css']
})
export class DailyInfoComponent implements OnInit {
  @ViewChild('table2') table2: ElementRef;

  term1;
  p1;
  p;
  constructor(
    public httpClient: HttpClient,
    public tableserviceService: TableserviceService) { }

  ngOnInit() {
  }
}
