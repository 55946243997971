import { Injectable } from '@angular/core';
import * as Highcharts from 'highcharts';
import * as Highstock from "highcharts/highstock";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
import { HttpClient } from '@angular/common/http';
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
// import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import am4themes_dark from "@amcharts/amcharts4/themes/dark"
import * as xlsx from 'xlsx';
am4core.useTheme(am4themes_animated);
am4core.useTheme(am4themes_dark);
import config from './../../../configuration.json';
import { ExportToCsv } from 'export-to-csv';
//import { TableComponent } from '../components/table/table.component';
import Hashids from 'hashids'

@Injectable({
  providedIn: 'root'
})
export class TableserviceService {
  public tableData: any = [];
  public tableDataCookie: any;
  public tableDataObj: any;
  public reg;
  public dailytotalsAOG: any = [];
  public msn;
  public acType;
  public operator;
  public onGroundArrayFinal: any = {};
  public datesUtilArr = [];
  public dailytotals: any = [];
  public fhArr: any = [];
  public mapChart: any;
  public placeSeries: any;
  public place: any;
  public setNewCookie = 'false';
  public assetData: any;
  public utilisationAcrossMonthBarChartLoader: boolean = false;
  public fleetActivityDayAircraft: number = 0; // Variables for daily activity cards
  public fleetActivityDayEngine: number = 0; // Variables for daily activity cards
  public fleetActivityMonthAircraft: number = 0; // Variables for daily activity cards
  public fleetActivityMonthEngine: number = 0; // Variables for daily activity cards
  public monthlyTotalsLoader: boolean = true;
  public incidentTableData: any[];
  public incidentTableLoader: boolean = false;

  constructor(private httpClient: HttpClient) { }
  page: number = 1

  // function to pull daily flights for a paticular lessors aircraft in a month and sum the daily individual flights
  // to get daily totals for an aircraft across the month
  monthlyBreakdown(tableData, setNewCookie) {
    this.setNewCookie = setNewCookie;
    // console.log(this.setNewCookie);
    let hex;
    this.assetData = tableData;

    // console.log("in monthly breakdown");
    // console.log(tableData);
    // console.log(tableData.hex);
    // console.log(tableData.monthEnding);

    //change 30-Jun-2020 -> 2020-06%
    if (this.setNewCookie == 'true') {
      var year = tableData.monthEnding.split('-')[2]
      var month = tableData.monthEnding.split('-')[1]
      this.tableDataCookie = { hex: tableData.hex, monthEnding: tableData.monthEnding };
      hex = tableData.hex;
    } else {
      var year = this.tableDataCookie.monthEnding.split('-')[2]
      var month = this.tableDataCookie.monthEnding.split('-')[1]
      hex = this.tableDataCookie.hex;
    }
    // console.log(year);
    // console.log(month);
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'] //month index
    var monthIndex = months.indexOf(month) + 1;
    let monthIndexStr;
    if (monthIndex < 10) {
      monthIndexStr = '0' + monthIndex;
    } else {
      monthIndexStr = monthIndex;
    }

    var dailyDate = year + "-" + monthIndexStr;
    this.tableData = [];
    this.fhArr = [];

    var __this = this;
    this.httpClient.get(config.apiUrl + '/uniform/dailytotals/' + hex + '/' + dailyDate + '/')
      .toPromise()
      .then((response: any) => {
        if (response.response === 200) {
          let dailytotals = response.data;
          // console.log(dailytotals);
          __this.reg = dailytotals[0].registration
          __this.msn = dailytotals[0].serialNumber
          __this.acType = dailytotals[0].assetModelSeries
          __this.operator = dailytotals[0].operator
          __this.dailytotals = dailytotals;
          __this.datesUtilArr = []
          var tableDataObj = {};

          //loop through all daily flights
          for (var i = 0; i < __this.dailytotals.length; i++) {
            var datesUtil: any = {};
            datesUtil.date = dailytotals[i].date
            datesUtil.flightHours = dailytotals[i].flightHours
            datesUtil.flightCycles = dailytotals[i].flightCycles
            __this.datesUtilArr.push(datesUtil)

            //if a completed cycle
            if (dailytotals[i].stopDateTime != null && dailytotals[i].flightCycles == 1) {
              // console.log("Flag 1");
              dailytotals[i].date.split(' ')[0]
              dailytotals[i].date = dailytotals[i].date.split(' ')[0]
              dailytotals[i].startDateTime = dailytotals[i].startDateTime.split(' ')[1]
              dailytotals[i].stopDateTime = dailytotals[i].stopDateTime.split(' ')[1]

              if (tableDataObj[dailytotals[i].date]) {
                // console.log("Flag 2");
                // console.log("Have seen");
                // console.log(tableDataObj[dailytotals[i].date]);
                // console.log( tableDataObj[dailytotals[i].date].flightCycles);
                // console.log(dailytotals[i].flightCycles);
                // console.log(tableDataObj[dailytotals[i].date].flightHours);
                // console.log(dailytotals[i].flightHours);
                // console.log(tableDataObj[dailytotals[i].date]);
                tableDataObj[dailytotals[i].date].flightCycles = tableDataObj[dailytotals[i].date].flightCycles + dailytotals[i].flightCycles;
                tableDataObj[dailytotals[i].date].flightHours = tableDataObj[dailytotals[i].date].flightHours + dailytotals[i].flightHours;
              } else {
                // console.log("Flag 3");
                // console.log("Have NOT seen");
                tableDataObj[dailytotals[i].date] = dailytotals[i]
              }
            } else {
              // console.log("Flag 4");
              dailytotals[i].date = dailytotals[i].date.split(' ')[0]
              dailytotals[i].startDateTime = dailytotals[i].startDateTime.split(' ')[1]
              // tableDataObj = [dailytotals[i].date, dailytotals[i]]
            }
          }
          // console.log("__this.tableData obj");
          // console.log(tableDataObj);
          __this.tableDataObj = tableDataObj
          // console.log(Object.values(__this.tableDataObj));
          __this.tableData.push(Object.values(__this.tableDataObj))

          // console.log("__this.tableData");
          // console.log(__this.tableData);

          //console.log(__this.tableData[0][0].registration);
          // console.log(__this.tableData[0].length);

          for (var i = 0; i < __this.tableData[0].length; i++) {
            __this.fhArr.push(+(__this.tableData[0][i].flightHours.toFixed(2)));
          }
          // console.log("fhARR");
          // console.log(__this.fhArr);
          // console.log(__this.datesUtilArr);
          __this.graphUtilisation();
          // __this.tableDataCookie = {hex:__this.tableData[0][0].hex, monthEnding:  __this.tableData[0][0].monthEnding};
          // console.log("this.tableDataCookie monthly");
          // console.log(__this.tableDataCookie);
        }
      });
  }


  getMonthlyFlightList(): void {
    // console.log(this.tableserviceService.dailyBreakdown(this.dailytotals));
    // console.log(this.assetData);
    // console.log(this.assetData.hex);
    var date = this.assetData.monthEnding;
    // this.tableData = [];
    let month = date.split("-")[1];
    let day = date.split("-")[0];
    let year = date.split("-")[2];
    let monthStr = day + "-" + month;
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'] //month index

    var monthIndex = months.indexOf(month) + 1;
    let monthIndexStr;
    if (monthIndex < 10) {
      monthIndexStr = '0' + monthIndex;
    } else {
      monthIndexStr = monthIndex;
    }

    var dailyDate = year + "-" + monthIndexStr;
    // console.log(dailyDate);
    // console.log(monthStr);
    var __this = this;
    this.httpClient.get(config.apiUrl + '/uniform/dailytotals/' + this.assetData.hex + '/' + dailyDate)
      .toPromise()
      .then((response: any) => {
        if (response.response === 200) {
          let dailytotals = response.data;
          let updatedDailyTotalsArr = [];
          for (var i = 0; i < Object.keys(dailytotals).length; i++) {
            if (dailytotals[i].flightCycles == 1) {
              let updatedDailyTotals: any = {};
              updatedDailyTotals.serialNumber = dailytotals[i].serialNumber;
              updatedDailyTotals.operator = dailytotals[i].operator;
              updatedDailyTotals.registration = dailytotals[i].registration;
              updatedDailyTotals.date = dailytotals[i].date;
              updatedDailyTotals.startTime = dailytotals[i].startDateTime;
              updatedDailyTotals.stopTime = dailytotals[i].stopDateTime;
              updatedDailyTotals.departureCountry = dailytotals[i].departureCountry;
              updatedDailyTotals.departureCity = dailytotals[i].departureCity;
              updatedDailyTotals.departureAirport = dailytotals[i].departureAirport;
              updatedDailyTotals.arrivalCountry = dailytotals[i].arrivalCountry;
              updatedDailyTotals.arrivalAirport = dailytotals[i].arrivalAirport;
              updatedDailyTotals.arrivalCity = dailytotals[i].arrivalCity;
              updatedDailyTotals.fh = dailytotals[i].flightHours;
              updatedDailyTotals.fc = dailytotals[i].flightCycles;

              updatedDailyTotalsArr.push(updatedDailyTotals)
            }
          }
          var filename = dailytotals[0].serialNumber + "_" + month + "_" + year;
          const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
            filename: filename,
          };

          const csvExporter = new ExportToCsv(options);
          csvExporter.generateCsv(updatedDailyTotalsArr);
          //console.log(updatedDailyTotalsArr);
        }
      });
  }

  getAOG(serialNumber: any, day: string = "", monthYear: string = "") {
    var __this = this;
    // console.log("Getting AOG...");
    // window.console.log(day, "<->", monthYear)
    // Reinitialise the list
    __this.dailytotalsAOG = []
    return this.httpClient.post(config.apiUrl + '/uniform/report/aircraftonground', serialNumber)
      .toPromise()
      .then((response: any) => {
        if (response.response === 200) {
          let res = response.data;
          // console.log('res');
          // console.log("get AOG FLAG 1Response");
          // console.log(res);
          for (var k = 0; k < res.length; k++) {
            if (res[k].length > 0) {
              __this.dailytotalsAOG.push(res[k]);
            }
          }
          // console.log("AOG NULL VALUES REMOVED")
          // console.log(__this.dailytotalsAOG);
          let date: Date = new Date();
          // Reinitialise the variables
          __this.fleetActivityDayAircraft = 0
          __this.fleetActivityMonthAircraft = 0
          __this.fleetActivityDayEngine = 0
          __this.fleetActivityMonthEngine = 0

          for (var x = 0; x < __this.dailytotalsAOG.length; x++) {
            let newDate = new Date(__this.dailytotalsAOG[x][0].stopDateTime);

            // Code for the daily activity cards starts
            if (day !== '') {
              let dailytotalsAOG = __this.dailytotalsAOG[x][0];
              let dateTimeSplit = dailytotalsAOG.date.split(' ')
              let dateSplit = dateTimeSplit[0].split('-')
              let flightMonthYear = dateSplit[0] + '-' + dateSplit[1]
              if (dailytotalsAOG.assetType === 'Aircraft' && dailytotalsAOG.flightCycles === 1) {
                if (day === dateTimeSplit[0]) {
                  // console.log('Aircraft ', __this.day, '===', dateTimeSplit[0])
                  __this.fleetActivityDayAircraft += 1
                  __this.fleetActivityMonthAircraft += 1
                } else if (monthYear === flightMonthYear) {
                  // console.log('Aircraft ', __this.day, '!==', dateTimeSplit[0])
                  __this.fleetActivityMonthAircraft += 1
                }
              } else if (dailytotalsAOG.assetType === 'Engine' && dailytotalsAOG.flightCycles === 1) {
                if (day === dateTimeSplit[0]) {
                  // console.log('Engine ', __this.day, '===', dateTimeSplit[0])
                  __this.fleetActivityDayEngine += 1
                  __this.fleetActivityMonthEngine += 1
                } else if (monthYear === flightMonthYear) {
                  // console.log('Engine ', __this.day, '!==', dateTimeSplit[0])
                  __this.fleetActivityMonthEngine += 1
                }
              }
            }
            // Code for the daily activity cards ends
            if (!__this.dailytotalsAOG[x][0].stopDateTime) {
              __this.dailytotalsAOG[x].push({ "daysOnGround": "-" });
            } else {
              var diff = Math.abs(date.getTime() - newDate.getTime());
              //console.log(diff);
              var daysOnGround = Math.floor(diff / (1000 * 3600 * 24));
              __this.dailytotalsAOG[x].push({ "daysOnGround": daysOnGround });
            }
            //console.log(newDate);
            //console.log(date.getTime());
            //console.log(newDate.getTime());
          }
          //console.log("AOG inserted into AOG REFINED ARRAY");
          for (let keys in __this.dailytotalsAOG) {
            let serialNumber = __this.dailytotalsAOG[keys][0].serialNumber;
            __this.onGroundArrayFinal[serialNumber] = __this.dailytotalsAOG[keys][0];
            __this.onGroundArrayFinal[serialNumber].daysOnGround = __this.dailytotalsAOG[keys][1].daysOnGround;
          }
          // console.log("ON GROUND ARRAY FINAL");
          // console.log(__this.onGroundArrayFinal);
        }
      })
  }

  dailyBreakdown(tableData) {
    /*console.log("in daily breakdown");
    console.log(tableData);
    console.log(tableData.hex);*/
    var date = tableData.date;
    // console.log(tableData.date);
    this.tableData = [];
    var dateFlightArr = [];

    //2020-11-01
    let month = tableData.date.split("-")[1]

    let year = tableData.date.split("-")[0]

    let monthStr = year + "-" + month;
    var __this = this;
    this.httpClient.get(config.apiUrl + '/uniform/dailytotals/' + tableData.hex + '/' + monthStr)
      .toPromise()
      .then((response: any) => {
        if (response.response === 200) {
          let dailytotals = response.data
          // console.log(dailytotals);
          __this.reg = dailytotals[0].registration;
          __this.msn = dailytotals[0].serialNumber;
          __this.acType = dailytotals[0].assetModelSeries;
          __this.operator = dailytotals[0].operator;
          __this.dailytotals = dailytotals;
          __this.datesUtilArr = [];

          for (let i = 0; i < __this.dailytotals.length; i++) {
            let datesUtil: any = {};
            datesUtil.date = dailytotals[i].date;
            datesUtil.flightHours = dailytotals[i].flightHours;
            datesUtil.flightCycles = dailytotals[i].flightCycles;
            __this.datesUtilArr.push(datesUtil);
            // console.log("date");
            // console.log(date);
            var date1 = dailytotals[i].date.split(' ')[0];
            // console.log(date1);
            // console.log(dailytotals[i].stopDateTime);
            // console.log(dailytotals[i].flightCycles);

            if (dailytotals[i].stopDateTime != null && dailytotals[i].flightCycles == 1 && date1 == date) {
              // console.log("Match");
              dailytotals[i].date.split(' ')[0]
              // console.log(dailytotals[i].date.split(' ')[1]);
              dailytotals[i].date = dailytotals[i].date.split(' ')[0]
              dailytotals[i].startDateTime = dailytotals[i].startDateTime.split(' ')[1]
              dailytotals[i].stopDateTime = dailytotals[i].stopDateTime.split(' ')[1]
              __this.tableData.push(dailytotals[i])
            } else {
              dailytotals[i].date = dailytotals[i].date.split(' ')[0]
              dailytotals[i].startDateTime = dailytotals[i].startDateTime.split(' ')[1]
            }
          }
          // console.log(__this.tableData);
          // console.log(__this.datesUtilArr);
          __this.dailyMap(__this.tableData);
        }

      })

  }

  graphUtilisation() {
    let month;
    let year;
    let monthNum;
    let yearNum;
    var dates = [];
    var dateArr = []
    var fcArr = []
    var fhArr = []
    let __this = this;

    // console.log("Graphng util");
    // console.log("this.datesUtilArr");
    // console.log(this.datesUtilArr);
    // console.log("this.tableData");
    // console.log(this.tableData);
    // console.log("this.tableDataObj");
    // console.log(this.tableDataObj);

    if (this.tableData.length > 0) {
      // console.log("this.tableData>0");
      // console.log(this.tableData[0])
      // console.log(this.tableData[0][0].date);

      month = this.tableData[0][0].date;
      year = month.split('-')[0];
      month = month.split('-')[1]
      monthNum = + month;
      yearNum = + year;
    }


    var days = this.getDaysInMonthUTC(monthNum, yearNum)
    // console.log("days: " + days);

    // console.log("__this.tableDataObj: ");
    // console.log(this.tableDataObj);

    let flownDates = [];

    for (var key in this.tableDataObj) {
      if (this.tableDataObj.hasOwnProperty(key)) {
        // console.log(key + " -> " + this.tableDataObj[key]);
        // console.log(this.tableDataObj[key].date);
        let date = this.tableDataObj[key].date;
        date = date.split('-')[2]
        // console.log(date);
        flownDates.push(date)

      }
    }
    // console.log(flownDates);


    //loop through days

    for (var j = 0; j < days.length; j++) {
      // console.log(j);
      // console.log(days[j]);
      if (flownDates.includes(days[j])) {
        console.log("Aircraft was flown on this day..");
        console.log(days[j]);
      } else {
        console.log("Aircraft was NOTflown on this day..");
        console.log(days[j]);
        //2020-11-01
        let dateKey = year + "-" + month + "-" + days[j];
        console.log(dateKey);

        this.tableDataObj[dateKey] = { date: dateKey, flightCycles: 0, flightHours: 0 }

      }
    }

    console.log(this.tableDataObj);
    const sortObject = this.sortObject(this.tableDataObj)

    console.log(sortObject);

    console.log(Object.keys(sortObject));


    dateArr.push(Object.keys(sortObject))

    for (var k = 0; k < dateArr[0].length; k++) {
      console.log(k);


      // console.log(Object.values(sortObject)[k].flightCycles);
      // console.log(Object.values(sortObject)[k].flightHours);
      // fcArr.push(Object.values(sortObject)[k].flightCycles);
      // fhArr.push(+ (Object.values(sortObject)[k].flightHours.toFixed(2)))

      fcArr.push(Object.values(sortObject)[k]['flightCycles']);
      fhArr.push(+ (Object.values(sortObject)[k]['flightHours'].toFixed(2)))


    }
    console.log(dateArr);
    console.log(fcArr);
    console.log(fhArr);


    Highcharts.chart('container', {
      chart: {
        type: 'column',
        events: {
          load: function () {
            __this.utilisationAcrossMonthBarChartLoader = true
          }
        },
      },
      title: {
        text: 'Utilisation across month'
      },
      xAxis: {
        categories: dateArr[0],
        type: 'datetime'
      },
      rangeSelector: {
        enabled: true
      },
      yAxis: {
        min: 0,
        title: {
          text: 'FH/FC'
        }
      },
      tooltip: {
        pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
        shared: true
      },
      credits: {
        enabled: false
      },

      series: [
        {
          name: 'FH',
          data: fhArr,
          type: undefined,
          color: '#6D63FF'
        },
        {
          name: 'FC',
          data: fcArr,
          type: undefined,
          color: '#00EFA5'
        }
      ]
    });
  }

  sortObject(obj) {
    return Object.keys(obj).sort().reduce(function (result, key) {
      result[key] = obj[key];
      return result;
    }, {});
  }

  //function to return the number of days in a given month
  getDaysInMonthUTC(month, year) {
    month = month - 1;
    console.log("Getting number of days in month..");
    let date = new Date(Date.UTC(year, month, 1));
    let dateStr = date.toString()
    console.log(date);
    console.log(dateStr);

    var days = [];
    while (date.getUTCMonth() === month) {
      // date = new Date(date);
      dateStr = date.toString()
      // console.log("dateSTR: " +dateStr);

      dateStr = dateStr.split(" ")[2]
      days.push(dateStr);
      date.setUTCDate(date.getUTCDate() + 1);
    }
    return days;
  }

  dailyMap(data) {
    console.log("in daily map...");
    console.log(data);

    for (var i = 0; i < data.length; i++) {

      var firstLatNum: number = +data[i].firstLat;
      var firstLngNum: number = +data[i].firstLng;

      var lastLatNum: number = +data[i].lastLat;
      var lastLngNum: number = +data[i].lastLng;

      console.log("firstLngNum: " + firstLngNum + "firstLatNum: " + firstLatNum);


      data[i].firstLat = firstLatNum
      data[i].firstLng = firstLngNum

      data[i].lastLat = lastLatNum
      data[i].lastLng = lastLngNum


    }
    console.log(data);



    var container: any = am4core.create("chartdiv", am4core.Container);
    container.layout = "vertical";
    container.width = am4core.percent(100);
    container.height = am4core.percent(100);
    container.background = new am4core.RoundedRectangle();
    container.background.fill = am4core.color("#000000");
    container.background.cornerRadius(30, 30, 30, 30)
    container.background.fillOpacity = 0.25;
    container.tooltip = new am4core.Tooltip();


    // map chart ////////////////////////////////////////////////////////
    this.mapChart = container.createChild(am4maps.MapChart);
    this.mapChart.geodata = am4geodata_worldLow;
    this.mapChart.projection = new am4maps.projections.Miller();
    this.mapChart.seriesContainer.draggable = true;
    this.mapChart.zoomControl = new am4maps.ZoomControl();
    this.mapChart.zoomControl.align = "right";
    this.mapChart.zoomControl.marginRight = 15;
    this.mapChart.zoomControl.valign = "middle";


    var polygonSeries = this.mapChart.series.push(new am4maps.MapPolygonSeries());
    polygonSeries.hiddenInLegend = true;
    polygonSeries.useGeodata = true;
    polygonSeries.exclude = ["AQ"]; // Antarctica is excluded in non-globe projection
    this.mapChart.homeGeoPoint = { longitude: 0, latitude: 15 };

    this.mapChart.homeZoomLevel = 1;
    // switch between map and globe
    var mapGlobeSwitch = this.mapChart.createChild(am4core.SwitchButton);
    mapGlobeSwitch.align = "right"
    mapGlobeSwitch.y = 15;
    mapGlobeSwitch.leftLabel.text = "Map";
    mapGlobeSwitch.leftLabel.fill = am4core.color("#ffffff");
    mapGlobeSwitch.rightLabel.fill = am4core.color("#ffffff");
    mapGlobeSwitch.rightLabel.text = "Globe";
    mapGlobeSwitch.verticalCenter = "top";
    var ___this = this;

    mapGlobeSwitch.events.on("toggled", function () {
      if (mapGlobeSwitch.isActive) {
        ___this.mapChart.projection = new am4maps.projections.Orthographic;
        ___this.mapChart.backgroundSeries.show();
        ___this.mapChart.panBehavior = "rotateLongLat";
        polygonSeries.exclude = [];
      } else {
        ___this.mapChart.projection = new am4maps.projections.Miller;
        ___this.mapChart.panBehavior = "move";
        polygonSeries.exclude = ["AQ"];
        // polygonSeries.data = data;
        polygonSeries.exclude = ["AQ"];
      }
    })

    this.placeSeries = this.mapChart.series.push(new am4maps.MapImageSeries());
    this.placeSeries.name = 'Take Off Location'
    this.placeSeries.fill = am4core.color("#00efa5");
    this.place = this.placeSeries.mapImages.template;

    this.place.nonScaling = true;
    this.place.propertyFields.latitude = "firstLat";
    this.place.propertyFields.longitude = "firstLng";

    this.placeSeries.data = data

    var circle = this.place.createChild(am4core.Circle);
    circle.radius = 8;
    circle.fill = am4core.color("#00efa5");

    circle.stroke = am4core.color("#fff");
    circle.strokeWidth = 2;
    if (data[0].assetType == "Aircraft") {
      circle.tooltipText = "[bold]Start Ping: {startDateTime}\nReg: {registration}\n[bold]MSN: {serialNumber}\n[bold]Type: {assetModelSeries}\n[bold]Operator: {operator}\n[bold]Takeoff Airport: {departureAirport}, [bold]{departureCity}, [bold]{departureCountry}";
    } else {
      circle.tooltipText = "[bold]Start Ping: {startDateTime}\nReg: {registration}\n[bold]ESN: {serialNumber}\n[bold]Type: {assetModelSeries}\n[bold]Operator: {operator}\n[bold]Takeoff Airport: {departureAirport}, [bold]{departureCity}, [bold]{departureCountry}";
    }



    this.placeSeries = this.mapChart.series.push(new am4maps.MapImageSeries());
    this.placeSeries.name = 'Landing Location'
    this.placeSeries.fill = am4core.color("#0000FF");
    this.place = this.placeSeries.mapImages.template;

    this.place.nonScaling = true;
    this.place.propertyFields.latitude = "lastLat";
    this.place.propertyFields.longitude = "lastLng";
    this.placeSeries.data = data


    var circle = this.place.createChild(am4core.Circle);
    circle.radius = 5;
    circle.fill = am4core.color("#0000FF");
    circle.stroke = am4core.color("#fff");
    circle.strokeWidth = 2;
    if (data[0].assetType == "Aircraft") {
      circle.tooltipText = "[bold]End Ping: {stopDateTime}\n[bold]Reg: {registration}\n[bold]MSN: {serialNumber}\n[bold]Type: {assetModelSeries}\n[bold]\n[bold]Operator: {operator}\n[bold]Landing Airport: {arrivalAirport}, [bold]{arrivalCity}, [bold]{arrivalCountry}";
    } else {
      circle.tooltipText = "[bold]End Ping: {stopDateTime}\n[bold]Reg: {registration}\n[bold]ESN: {serialNumber}\n[bold]Type: {assetModelSeries}\n[bold]Operator: {operator}\n[bold]Landing Airport: [bold]{arrivalAirport}, [bold]{arrivalCity}, {arrivalCountry}";
    }


    this.mapChart.legend = new am4maps.Legend();
    this.mapChart.legend.useDefaultMarker = true;
    let marker = this.mapChart.legend.markers.template.children.getIndex(0);
    marker.cornerRadius(12, 12, 12, 12);
    marker.strokeWidth = 2;
    marker.strokeOpacity = 1;
    // marker.fill = circle.fill
    marker.stroke = am4core.color("#ccc");


    //line series
    var multiGeoArr = [];

    for (var i = 0; i < data.length; i++) {
      console.log(data[i]);
      var multiGeoObj: any = {};
      multiGeoObj.latitude = data[i].firstLat
      multiGeoObj.longitude = data[i].firstLng
      multiGeoArr.push(multiGeoObj)
      multiGeoObj = {};
      multiGeoObj.latitude = data[i].lastLat
      multiGeoObj.longitude = data[i].lastLng
      multiGeoArr.push(multiGeoObj)
    }
    var lineSeries = this.mapChart.series.push(new am4maps.MapLineSeries());
    lineSeries.hiddenInLegend = true;
    lineSeries.mapLines.template.strokeWidth = 0.1;
    lineSeries.mapLines.template.stroke = am4core.color("#6D63FF");
    lineSeries.mapLines.template.line.tensionX = 0.6;
    lineSeries.mapLines.template.line.tensionY = 0.6;
    console.log("multiGeoArr: ");
    console.log(multiGeoArr);

    lineSeries.data = [{
      "multiGeoLine": [multiGeoArr]
    }];
    lineSeries.mapLines.template.shortestDistance = false;

  }

  initMonthlyTable() {

    var currentDate = new Date();
    // currentDate = JSON.stringify(currentDate);
    let currentDateStr = currentDate.toString();
    //console.log("Current date: " + currentDateStr);
    let year = currentDateStr.split(' ')[3];
    //console.log(year);
    let month = currentDateStr.split(' ')[1];
    //console.log(month);

    //remove this code
    let lastDayOfMonth = new Date();
    let lastDay= new Date(lastDayOfMonth.getFullYear(), lastDayOfMonth.getMonth() + 1, 0);
    let lastDayString = lastDay.toString();
    let day = lastDayString.split(' ')[2];
    //console.log(day);
    let startMonth = day + '-' + month + '-' + year;
    //console.log(startMonth);
    let endMonth = day + '-' + month + '-' + year;
    //console.log(endMonth);

    var clientData;
    // this.getClientDetails()
    var __this = this;

    this.httpClient.get(config.apiUrl + '/uniform/monthlytotals/undefined/' + startMonth +'/' + endMonth)
      .toPromise()
      .then((response: any) => {
        //console.log(response);
        if (response.response === 200) {
          let monthlyTotals = response.data;
          let monthlyTotalsTableDataArr = [];
          for (var i = 0; i < Object.keys(monthlyTotals).length; i++) {
            let monthlyTotalsTableData: any = {};
            monthlyTotalsTableData.assetModelSeries = monthlyTotals[i].assetModelSeries;
            monthlyTotalsTableData.assetType = monthlyTotals[i].assetType;
            monthlyTotalsTableData.operator = monthlyTotals[i].operator;
            monthlyTotalsTableData.flightHours = monthlyTotals[i].flightHours;
            monthlyTotalsTableData.flightCycles = monthlyTotals[i].flightCycles;
            monthlyTotalsTableData.hex = monthlyTotals[i].hex;
            monthlyTotalsTableData.monthEnding = monthlyTotals[i].monthEnding;
            monthlyTotalsTableData.registration = monthlyTotals[i].registration;
            monthlyTotalsTableData.serialNumber = monthlyTotals[i].serialNumber;
            monthlyTotalsTableData.techRep = monthlyTotals[i].techRep;
            monthlyTotalsTableData.marketingRep = monthlyTotals[i].marketingRep;
            if (__this.onGroundArrayFinal.hasOwnProperty(monthlyTotalsTableData.serialNumber)) {
              monthlyTotalsTableData.daysOnGround = __this.onGroundArrayFinal[monthlyTotalsTableData.serialNumber].daysOnGround;
            }
            else {
              monthlyTotalsTableData.daysOnGround = '-';
            }
            monthlyTotalsTableDataArr.push(monthlyTotalsTableData);
          }
          let insightFinalData = [];
          for (var i = 0; i < monthlyTotalsTableDataArr.length; i++) {
            let insightData: any = {};
            let serialNumber = monthlyTotalsTableDataArr[i].serialNumber;
            if (!insightData.hasOwnProperty(serialNumber)) {
              insightData[serialNumber] = [];
            }
            insightData[serialNumber].flightCycles = monthlyTotalsTableDataArr[i].flightCycles;
            insightData[serialNumber].flightHours = monthlyTotalsTableDataArr[i].flightHours;
            insightFinalData.push(insightData);
            //console.log(insightFinalData);
          }
          __this.tableData = monthlyTotalsTableDataArr;
        }

      })
  }

  getIncidentList(lessor) {

    var __this = this;
    __this.incidentTableLoader = true;
    let date: Date = new Date();
    this.httpClient.get(config.apiUrl + '/uniform/incidents', lessor)
      .toPromise()
      .then((response: any) => {
        //console.log(monthlyTotals);
        if (response.response === 200) {
          let incidents = response.data;
          let incidentList = [];
          let salt = 'f5ifhcqy09pud7174ra5';
          for (let i = 0; i < Object.keys(incidents).length; i++) {
            let incidentTableData: any = {};
            incidentTableData.idIncident = incidents[i].idIncident;
            incidentTableData.title = incidents[i].title
            incidentTableData.incidentDate = (incidents[i].incidentDate.replace('Z', '').replace('T', ' ')).split(' ')[0];
            incidentTableData.createdDate = incidents[i].createdDate
            incidentTableData.updateDate = incidents[i].updateDate
            incidentTableData.type = incidents[i].type
            incidentTableData.aircraftType = incidents[i].aircraftType
            incidentTableData.aircraftReg = incidents[i].aircraftReg
            incidentTableData.airline = incidents[i].airline
            incidentTableData.flightNumber = incidents[i].flightNumber
            let currentTimeInSeconds = Math.floor(Date.now() / 1000);
            let validUntil = currentTimeInSeconds + (24 * 3600 * 2);
            let tsHash = new Hashids(salt);
            let finalHash = tsHash.encode(validUntil);
            incidentTableData.iframeURI = incidents[i].iframeURI + '?validUntil=' + finalHash;
            // console.log("FINAL IFRAME LINK");
            // console.log(incidentTableData.iframeURI);
            incidentTableData.cause = incidents[i].cause
            incidentTableData.publishState = incidents[i].publishState
            incidentTableData.testFlag = incidents[i].testFlag
            incidentTableData.articleId = incidents[i].articleId
            incidentTableData.idClientInfo = incidents[i].idClientInfo
            incidentTableData.registration = incidents[i].registration
            incidentTableData.serialNumber = incidents[i].serialNumber
            incidentTableData.hex = incidents[i].hex
            incidentTableData.assetType = incidents[i].assetType
            incidentTableData.assetModelSeries = incidents[i].assetModelSeries
            incidentTableData.operator = incidents[i].operator
            incidentTableData.techRep = incidents[i].techRep
            incidentTableData.marketingRep = incidents[i].marketingRep
            incidentTableData.lessor = incidents[i].lessor
            incidentList.push(incidentTableData);
          }
          //__this.incidentTableData = incidentList;
          __this.incidentTableData = incidentList.sort(function (a, b) {
            return new Date(b.incidentDate).valueOf() - new Date(a.incidentDate).valueOf();
          });
          //console.log(__this.incidentTableData);
          __this.incidentTableLoader = false;
        }
      });
  }
  exportToCsv(table_id, separator = ',') {
    var rows = document.querySelectorAll('#' + table_id + ' tr');
    // Construct csv
    var csv = [];
    for (var i = 0; i < rows.length; i++) {
      var row = [], cols = rows[i].querySelectorAll('td, th');
      for (var j = 0; j < cols.length; j++) {
        // Clean innertext to remove multiple spaces and jumpline (break csv)
        var data = (cols[j] as HTMLElement).innerText.replace(/(\r\n|\n|\r)/gm, '').replace(/(\s\s)/gm, ' ')

        data = data.replace(/"/g, '""');
        // Push escaped string
        row.push('"' + data + '"');
      }
      csv.push(row.join(separator));
    }
    var csv_string = csv.join('\n');
    // Download it
    var filename = table_id + '.csv';
    var link = document.createElement('a');
    link.style.display = 'none';
    link.setAttribute('target', '_blank');
    link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv_string));
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link)
  }
}
