import {Component, OnInit, ViewChild, ElementRef, NgModule} from '@angular/core';
import { TableserviceService } from '../../services/tableservice.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as xlsx from 'xlsx';
import { HttpClient } from '@angular/common/http';
import config from '../../../../configuration.json'
import {FormControl, ReactiveFormsModule, FormBuilder, FormGroup, FormsModule} from '@angular/forms';
import { serializeUrl } from '@amcharts/amcharts4/.internal/core/utils/Utils';
import { ExportToCsv } from 'export-to-csv';
import { ThrowStmt } from '@angular/compiler';
import moment from 'moment';
declare var $: any;


@Component({
  selector: 'app-daily-table',
  templateUrl: './daily-table.component.html',
  styleUrls: ['./daily-table.component.css']
})
export class DailyTableComponent implements OnInit {
  dailyFlightsInAir = [];
  dailytotals = [];
  dateRange: string;
  months: Array<string> = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  dailyTableLoader: boolean = true;
  smartTableValues: any;
  startDay: string;
  endDay: string;
  startDayNew: string;
  endDayNew: string;
  latestDate = [];
  allDailyFlights = [];
  isGroundBased: boolean = true;
  operator: string = '';
  arrival: string = '';
  departure: string = '';


  @ViewChild('table1') table2: ElementRef;

  constructor(
    public tableService: TableserviceService,
    public formBuilder: FormBuilder,
    public httpClient: HttpClient) { }
  page: number = 1

  settings = {
    actions: false,
    defaultStyle: false,
    pager: {
      display: false
    },
    noDataMessage: 'No data to display',
    columns: {
      serialNumber: {
        title: 'SERIAL NUMBER'
      },
      registration: {
        title: 'REGISTRATION'
      },
      operator: {
        title: 'OPERATOR'
      },
      date: {
        title: 'DATE'
      },
      startDateTime: {
        title: 'START TIME'
      },
      stopDateTime: {
        title: 'STOP TIME'
      },
      departure: {
        title: 'DEPARTURE',
      },
      arrival: {
        title: 'ARRIVAL',
      },
      flightHours: {
        title: 'FLIGHT HOURS'
      },
      flightCycles: {
        title: 'FLIGHT CYCLES'
      }
    }
  };

  ngOnInit() {
    //this.clicked = true;
    this.getLatestDate();
  }

  getLatestDate() {
    let __this = this;
    __this.httpClient.get(config.apiUrl + '/uniform/latestday').toPromise().then((response: any) => {
      //console.log(response);
      __this.latestDate = response.data[0].date
      let latestDateStr = __this.latestDate.toString();
      let finalDate = latestDateStr.split(' ');
      let monthNum = parseInt(finalDate[0].split('-')[1]) - 1;
      let monthNumUI = moment().month(monthNum).format("MMM");
      let dayUI = finalDate[0].split('-')[2];
      let yearUI = finalDate[0].split('-')[0];
      __this.startDay = finalDate[0];
      __this.endDay = finalDate[0];
      __this.startDayNew = dayUI + '-' + monthNumUI + '-' + yearUI;
      __this.endDayNew = dayUI + '-' + monthNumUI + '-' + yearUI;
      __this.getDailyData(this.startDay, this.endDay);
      //__this.getGroundBased(this.startDay, this.endDay);

    })
  }

  getDailyData(startDay, endDay) {
    let __this = this;
    __this.smartTableValues = {
      operator: __this.operator,
      arrival: __this.arrival,
      departure: __this.departure
    };
    __this.httpClient.post(config.apiUrl + '/uniform/dailytotals/all/' + startDay + '/' + endDay, __this.smartTableValues).toPromise().then((response: any) => {
      if (response.response === 200) {
        __this.dailyFlightsInAir = response.data;
        __this.dailyTableLoader = false;
      }
    })
  }

  getGroundBased() {
    let __this = this;
    if (!__this.isGroundBased) {
           __this.allDailyFlights = __this.dailyFlightsInAir;
      }else {
        let value = '1';
        __this.httpClient.post(config.apiUrl + '/uniform/dailytotals/all/' + __this.startDay + '/' + __this.endDay + '/' + value,  __this.smartTableValues).toPromise().then((response: any) => {
          if (response.response === 200) {
            __this.allDailyFlights = response.data;
          }
        })
      }
      __this.isGroundBased = !__this.isGroundBased;
    }

    ngAfterViewInit() {
      let __this = this;
      $(".startDate").datepicker({
        autoclose: true,
        format: 'yyyy-mm-dd',
        startView: "months",
        minViewMode: "days",
        endDate: '+0d'
      }).on('changeDate', function (selected) {
        //var __this = this;
        let startMonth = new Date(selected.date);
        //console.log(startMonth);
        let startMonthStr = startMonth.toString();
        let month = startMonthStr.split(' ')[1]
        let monthNum = ("JanFebMarAprMayJunJulAugSepOctNovDec".indexOf(month) / 3 + 1);
        let monthNumnew = '0' + monthNum;
        let year = startMonthStr.split(' ')[3]
        let day = startMonthStr.split(' ')[2];
        __this.startDay = year + '-' + monthNumnew + '-' + day;
        __this.startDayNew = day + '-' + month + '-' + year;
        //console.log(this.startDay);
        startMonth.setDate(startMonth.getDate() + 1);
        $('.endDate').datepicker('setStartDate', startMonth);
      });

      $(".endDate").datepicker({
        autoclose: true,
        format: 'yyyy-mm-dd',
        startView: "months",
        minViewMode: "days",
        endDate: '+0d'
      }).on('changeDate', function (selected) {
        var endMonth = new Date(selected.date);
        var endMonthStr = endMonth.toString();
        let day = endMonthStr.split(' ')[2]
        let month = endMonthStr.split(' ')[1]
        let year = endMonthStr.split(' ')[3]
        let monthNum = ("JanFebMarAprMayJunJulAugSepOctNovDec".indexOf(month) / 3 + 1);
        let monthNumnew = '0' + monthNum;
        __this.endDay = year + '-' + monthNumnew + '-' + day;
        __this.endDayNew = day + '-' + month + '-' + year;

      });
    }

  clearFilters() {
    let __this = this;
    __this.operator = '';
    __this.arrival = '';
    __this.departure  = '';
    this.startDay = this.startDay;
    this.endDay = this.endDay;
    //console.log(this.endDay)
    __this.getDailyData(__this.startDay, __this.endDay);
  }

    onSubmit() {
      let __this = this;
      this.dailyTableLoader = true; // Show the loader
      // this.fetchData(dateRange);
      this.startDay = this.startDay;
      this.endDay = this.endDay;
      //console.log(this.endDay)
      __this.getDailyData(__this.startDay, __this.endDay);
    }
  }

// exportToCsv() {
  //   let dailyExportData = this.dailytotals1;
  //   let resultDailyData = dailyExportData.map(({serialNumber,registration,operator,date,startDateTime,stopDateTime,departure,arrival,flightHours,flightCycles})=> ({serialNumber,registration,operator,date,startDateTime,stopDateTime,departure,arrival,flightHours,flightCycles}));
  //   // console.log('Export to csv',resultDailyData);
  //   // console.log('Inside Export to CSV Daily Table');

  //   var filename = "Export Daily"
  //   const options = {
  //     fieldSeparator: ',',
  //     quoteStrings: '"',
  //     decimalSeparator: '.',
  //     showLabels: true,
  //     useTextFile: false,
  //     useBom: true,
  //     useKeysAsHeaders: true,
  //     filename: filename,
  //   };
  //   const csvExporter = new ExportToCsv(options);
  //   csvExporter.generateCsv(resultDailyData);
  // }
